import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import { getUsedQuantity } from '../Utils/utils';

const UsedVoucher = (props) => {
  const [user_list, setUserList] = useState([]);
  const [code, setCode] = useState('');
  const [keyword, setKeyword] = useState('');

  useEffect(async () => {
    props.toggleLoader(true);
    var getList_var = await getUsedQuantity(props.match.params.id, keyword);
    setUserList(getList_var);
    setCode(getList_var[0].code)
    props.toggleLoader(false);
  }, [])

  const searchUserVoucher = async (e) => {
    setKeyword(e);
    var getList_var = await getUsedQuantity(props.match.params.id, e);
    setUserList(getList_var);
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar
              search_method={searchUserVoucher}
            />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><Link to="/discount-voucher">Discount Voucher</Link></li>
                          <li className="breadcrumb-item"><a href="#">Used Quantity</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">{code}</h3>
                        </div>

                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Offer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user_list.map((list) => (
                            <tr>
                              <td>{list.user.first_name} </td>
                              <td>{list.user.last_name}</td>
                              <td>{list.user.email}</td>
                              <td>{list.offer === null ? "" : list.offer.name}</td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>



            </div>


          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsedVoucher);
