import React from 'react';
import { displaySuccess } from '../../Utils/utils';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../../actions/loader';
import { connect } from 'react-redux';

const Topbar = ({ search_method = null }) => {

  const logout = () => {
    localStorage.removeItem('token');
    displaySuccess('Logout Successfull');
    setTimeout(() => window.location.href = "/", 3000);
  }
  return (
    <>
      <nav className="navbar navbar-main navbar-expand-lg border-bottom bg-primary">
        <div className="container-fluid py-1 px-3">
          {
            (search_method !== null) ?
              <div className="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
                <div className="form-group mb-0">
                  <div className="input-group input-group-alternative input-group-merge">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-search" /></span>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Search"
                      type="text"
                      onChange={e => search_method(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              : ''
          }
          <div className="collapse navbar-collapse mt-sm-0 mt-2 ml-auto" style={{ flexGrow: 'unset' }}>
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item px-3 d-flex align-items-center">
                <Link to="/change-password" className="nav-link text-body p-0">
                  <i className="fa fa-cog text-white cursor-pointer" />
                </Link>
              </li>
              <li className="nav-item dropdown pr-2 ml-2 mr-2 d-flex align-items-center">
                {/* <a href="#" className="nav-link text-body p-0">
                  <i className="fa fa-bell text-white cursor-pointer" />
                </a> */}
                <ul className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4">
                  <li className="mb-2">
                    <a className="dropdown-item border-radius-md" href="#">
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img src="img2/team-2.jpg" className="avatar avatar-sm  mr-3 " />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">New message</span> from Laur
                          </h6>
                          <p className="text-xs text-secondary mb-0">
                            <i className="fa fa-clock me-1" />
                            13 minutes ago
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a className="dropdown-item border-radius-md" href="#">
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img src="img2/small-logos/logo-spotify.svg" className="avatar avatar-sm bg-gradient-dark  mr-3 " />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">New album</span> by Travis Scott
                          </h6>
                          <p className="text-xs text-secondary mb-0">
                            <i className="fa fa-clock me-1" />
                            1 day
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item border-radius-md" href="#">
                      <div className="d-flex py-1">
                        <div className="avatar avatar-sm bg-gradient-secondary  mr-3  my-auto">
                          <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>credit-card</title>
                            <g id="Basic-Elements" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                              <g id="Rounded-Icons" transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                                <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
                                  <g id="credit-card" transform="translate(453.000000, 454.000000)">
                                    <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" id="Path" opacity="0.593633743" />
                                    <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z" id="Shape" />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            Payment successfully completed
                          </h6>
                          <p className="text-xs text-secondary mb-0">
                            <i className="fa fa-clock me-1" />
                            2 days
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item d-flex align-items-center">
                <a href="#" className="nav-link text-body text-white">
                  <img src="/img/logo-email.png" width="30px" className="rounded-circle" />
                  <a onClick={logout} className="d-sm-inline text-white d-none ml-2">Log out</a>
                </a>
              </li>
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a href="#" className="nav-link text-body p-0">
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);