import React, { useEffect, useState } from 'react';
import './dashboard.css';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { getCards, getPurchaseList } from '../Utils/utils';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';

const Dashboard = (props) => {
  const [count, setCount] = useState([]);
  const [num, setNum] = useState([]);

  useEffect(async () => {
    props.toggleLoader(true);
    var getcards_var = await getCards();
    console.log(getcards_var);
    setCount(getcards_var);
    props.toggleLoader(false);
  }, [])

  useEffect(async () => {
    props.toggleLoader(true);
    var getPurchase_var = await getPurchaseList();
    console.log(getPurchase_var);
    setNum(getPurchase_var);
    props.toggleLoader(false);
  }, [])
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <h6 className="h2 text-white d-inline-block mb-0">Dashboard</h6>
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><a href="#"><i className="fas fa-home" /></a></li>
                          <li className="breadcrumb-item"><a href="#">Components</a></li>
                          {/* <li className="breadcrumb-item active" aria-current="page">Cards</li> */}
                        </ol>
                      </nav>
                    </div>
                  </div>
                  {/* Card stats 1*/}

                  <div className="row">
                    <div className="col-xl-3 col-md-6">
                      <div className="card card-stats">
                        {/* Card body */}

                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Users</h6>
                              <span className="h2 font-weight-bold mb-0">{count.total_user}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                          <p className="mt-3 mb-0 text-sm">
                            {/* <span className="text-success mr-2"><i className="fa fa-arrow-up" />{count.total_user}</span> */}

                          </p>
                        </div>

                      </div>
                    </div>

                    {/* Card stats 2*/}

                    <div className="col-xl-3 col-md-6">
                      <div className="card card-stats">
                        {/* Card body */}

                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Offers</h6>
                              <span className="h2 font-weight-bold mb-0">{count.total_offer}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                          <p className="mt-3 mb-0 text-sm">
                            {/* <span className="text-success mr-2"><i className="fa fa-arrow-up" />{count.total_offer}</span> */}

                          </p>
                        </div>

                      </div>
                    </div>

                    {/* Card stats 3*/}

                    <div className="col-xl-3 col-md-6" >
                      <div className="card card-stats" style={{height:'95px'}}>
                        {/* Card body */}

                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Active Offers</h6>
                              <span className="h2 font-weight-bold mb-0">{count.active_offer}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>

                            <p className="mt-3 mb-0 text-sm">
                              {/* <span className="text-success mr-2"><i className="fa fa-arrow-up" />{count.active_offer}</span> */}

                            </p>
                          </div>

                        </div>
                      </div>
                    </div>
                    {/* Card stats 4*/}

                    <div className="col-xl-3 col-md-6">
                      <div className="card card-stats">
                        {/* Card body */}

                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Purchases</h6>
                              <span className="h2 font-weight-bold mb-0">{count.total_purchase}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                          <p className="mt-3 mb-0 text-sm">
                            {/* <span className="text-success mr-2"><i className="fa fa-arrow-up" />{count.total_purchase}</span> */}

                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row my-4">
                <div className="col-lg-12 col-md-6 mb-md-0 mb-4">
                  <div className="card">
                    <div className="card-header pb-0">
                      <div className="row">
                        <div className="col-lg-6 col-7">
                          <h6>Recent Purchases List</h6>

                        </div>
                        <div className="col-lg-6 col-5 my-auto text-right">
                          <div className="dropdown float-lg-end pe-4">
                            <a className="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
                              <i className="fa fa-ellipsis-v text-secondary" aria-hidden="true" />
                            </a>
                            <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                              <li><a className="dropdown-item border-radius-md" href="#">Action</a></li>
                              <li><a className="dropdown-item border-radius-md" href="#">Another action</a></li>
                              <li><a className="dropdown-item border-radius-md" href="#">Something else here</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pb-2">
                      <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Email</th>
                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Offer</th>
                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (num.length === 0)
                                ?
                                <tr><td>No Recent Purchases.</td></tr>
                                :
                                num.map((data) => (
                                  <tr>
                                    <td>
                                      <div className="d-flex px-2 py-1">
                                        <div>
                                          {/* {data.media !== null ?
                                            <img src={data.media.url} className="avatar avatar-sm mr-3" />
                                            : <img src="img2/small-logos/logo-xd.svg" className="avatar avatar-sm mr-3" />} */}

                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                          {data.user !== null ?
                                            <h6 className="mb-0 text-sm">{data.user.first_name} {data.user.last_name}</h6>
                                            : ""}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {data.user !== null ?
                                        <span className="text-xs font-weight-bold">{data.user.email}</span>
                                        : ""}

                                    </td>
                                    <td className="align-middle text-center text-sm">
                                      <span className="text-xs font-weight-bold">{data.offer.name} </span>
                                    </td>
                                    <td className="align-middle">
                                      <div className="progress-wrapper w-75 mx-auto">
                                        <div className="progress-info">
                                          <div className="progress-percentage">
                                            <span className="text-xs font-weight-bold">{data.status}</span>
                                          </div>
                                        </div>
                                        {/* <div className="progress">
                                          <div className="progress-bar bg-gradient-info w-60" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} />
                                        </div> */}
                                      </div>
                                    </td>
                                    <td className="table-actions">
                                      <Link className="btn btn-light" data-toggle="tooltip" data-original-title="Edit product">
                                        <i className="fas fa-user-edit" />
                                      </Link>

                                    </td>
                                  </tr>
                                ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
