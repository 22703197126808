import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { displayError, displaySuccess } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import config from '../config/config';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { getEditUser, getCountries, getStates, getCities } from '../Utils/utils';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form'
const EditUser = (props) => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const [description, setDescription] = useState('');
  const [countries, setCountries] = useState([]);
  const [country_id, setCountryId] = useState('');
  const [state_id, setStateId] = useState('');
  const [city_id, setCityId] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
const[user_type,setUserType]=useState('');

  const updateUser = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/user/update/' + props.match.params.id;
    axios.post(path, {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phoneNumber,
      gender: gender,
      country_id: country_id,
      state_id: state_id,
      city_id: city_id,
      description: description,
      user_type: user_type
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('user updated successfully');
        setTimeout(() => window.location.href = "/users", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }
  useEffect(async () => {
    var getEditUser_var = await getEditUser(props.match.params.id);
    console.log(getEditUser_var);
    setFirstName(getEditUser_var.first_name);
    setLastName(getEditUser_var.last_name);
    setEmail(getEditUser_var.email);
    setGender(getEditUser_var.gender);
    setPhoneNumber(getEditUser_var.phone_number);
    setDescription(getEditUser_var.description);
    setCountryId(getEditUser_var.country_id);
    setStateId(getEditUser_var.state_id);
    setCityId(getEditUser_var.city_id);
    if (getEditUser_var.state !== null) {
      var get_states = await getStates(getEditUser_var.country_id);
      setStates(get_states);
    }
    if (getEditUser_var.city !== null) {
      var get_cities = await getCities(getEditUser_var.state_id);
      setCities(get_cities);
    }
    setUserType(getEditUser_var.user_type);
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_countries = await getCountries();
    setCountries(get_countries);
    console.log(get_countries);
    props.toggleLoader(false)
  }, [])

  const fetchStates = async (country_id) => {
    props.toggleLoader(true);
    var get_states = await getStates(country_id);
    setStates(get_states);
    setCountryId(country_id)
    props.toggleLoader(false);
  }

  const fetchCities = async (state_id) => {
    props.toggleLoader(true);
    var get_cities = await getCities(state_id);
    setCities(get_cities);
    setStateId(state_id)
    props.toggleLoader(false);
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/users">Users </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit User</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Edit User</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >First Name</label>
                              <input type="text" className="form-control" placeholder="First Name"
                                value={first_name} onChange={e => setFirstName(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Last Name</label>
                              <input type="text" className="form-control" placeholder="Last Name"
                                value={last_name} onChange={e => setLastName(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Email </label>
                              <input type="text" className="form-control" placeholder="Email"
                                value={email} onChange={e => setEmail(e.target.value)} disabled/>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Phone number </label>
                              <input type="text" className="form-control" placeholder="Phone Number"
                                value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Gender</label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setGender(e.target.value)} value={gender}>
                                <option value="f">f</option>
                                <option selected Value="m">m</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Role</label>
                              <select className="form-control" id="validationCustom04" required=""
                              onChange={e=>setUserType(e.target.value)} value={user_type}>
                                <option value="1">Student</option>
                                <option Value="2">Instructor</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="form-control-label" >Select Country </label>
                            <select className="form-control" id="validationCustom04" required="" onChange={e => {
                              fetchStates(e.target.value)
                              setCountryId(e.target.value)
                            }}>
                              <option required>Select Country</option>
                              {countries.map((country) =>
                                <option value={country.id} selected={(country.id === country_id) ? true : false}>{country.name}</option>
                              )}
                            </select>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >State</label>
                              <select className="form-control" id="validationCustom04" required="" onChange={e => {
                                fetchCities(e.target.value)
                                setStateId(e.target.value)
                              }}>
                                <option required>Select State</option>
                                {states.map((state) =>
                                  <option value={state.id} selected={(state.id === state_id) ? true : false}>{state.name}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >City</label>
                              <select className="form-control" id="validationCustom04" required="" onChange={e =>
                                setCityId(e.target.value)} >
                                <option required>Select City</option>
                                {cities.map((city) =>
                                  <option value={city.id} selected={(city.id === city_id) ? true : false}>{city.name}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Description</label>
                              <textarea className="form-control" placeholder='Description'
                                value={description} onChange={e => setDescription(e.target.value)}></textarea>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary" onClick={updateUser}> <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);