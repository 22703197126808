import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { deactivateUser, getUsers } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import config from '../config/config';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { displayError, displaySuccess, getDiscountVoucher, voucherActive } from '../Utils/utils';
import ReactPaginate from 'react-paginate';
import moment from "moment";

const DiscountVoucher = (props) => {
  const [vouchers, setVouchers] = useState([]);
  const [temp_var_id, setTempVarId] = useState('');
  const [current_page, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [is_delete, setIsDelete] = useState(false);
  const [status, setStatus] = useState(0);

  useEffect(async () => {
    props.toggleLoader(true);
    var getDisc_var = await getDiscountVoucher(current_page, search);
    setVouchers(getDisc_var.data);
    setPageCount(getDisc_var.last_page);
    props.toggleLoader(false);
  }, [])

  const deleteVoucher = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + "/admin/discount-voucher/delete/" + temp_var_id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess('Deleted Successfully');
        var getDisc_var = await getDiscountVoucher(current_page, search);
        setVouchers(getDisc_var.data);
        setPageCount(getDisc_var.last_page);
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      });
  }


  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    props.toggleLoader(true);
    var getDisc_var = await getDiscountVoucher(selectedPage, search);
    setVouchers(getDisc_var.data);
    setPageCount(getDisc_var.last_page);
    props.toggleLoader(false);
  };

  // const userStatus = async () => {
  //   var token = localStorage.getItem("token");
  //   props.toggleLoader(true);
  //   let path = config.siteUrl;
  //   var deactivateUser_var = await deactivateUser(temp_var_id, temp_var_status);
  //   window.location.reload()
  // }

  const searchVoucher = async (e) => {
    setSearch(e);
    var getDisc_var = await getDiscountVoucher(1, e);
    setPageCount(getDisc_var.last_page)
    setVouchers(getDisc_var.data);
  }
  const activeVoucher = async (id, status) => {
    props.toggleLoader(true);
    if (status === 1) {
      var active_var = await voucherActive(id, 1);
    }
    else {
      var active_var = await voucherActive(id, 0);
    }
    setStatus(active_var);
    var getDisc_var = await getDiscountVoucher(current_page, search);
    setVouchers(getDisc_var.data);
    setPageCount(getDisc_var.last_page);
    props.toggleLoader(false);
  }

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar
              search_method={searchVoucher}
            />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Discount Voucher</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Discount Vouchers Listing </h3>
                        </div>
                        <div class="col-6 text-right">
                          <Link to='/discount-voucher/add'>
                            <a href="" class="btn btn-dark mr-2" data-toggle="tooltip" data-original-title="Edit product">
                              <span class="btn-inner--icon"><i class="fas fa-user-edit"></i></span>
                              <span class="btn-inner--text"> Add Voucher</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th> Code</th>
                            <th>Discount Percentage</th>
                            <th>Discount Value</th>
                            <th>Offer</th>
                            <th>User</th>
                            <th>Date</th>
                            <th>Quantity </th>
                            <th>Used Quantity</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vouchers.map((disc) => (
                            <tr>
                              <td>{disc.code}</td>
                              <td>{disc.discount_percentage === null ? "" : disc.discount_percentage + '%'}</td>
                              <td>{disc.discount_value === null ? "" : 'R$ ' + disc.discount_value}</td>
                              <td>{disc.offer === null ? "" : disc.offer.name}</td>
                              <td>{disc.user === null ? "" : disc.user.first_name + " " + disc.user.last_name}<br />
                                {disc.user === null ? "" : "(" + disc.user.email + ")"}</td>
                              <td>{disc.start_date === null ? "" : (moment(disc.start_date).format("DD/MMM"))} - {disc.end_date === null ? "" : (moment(disc.end_date).format("DD/MMM"))}</td>
                              <td>{disc.quantity === null ? "" : disc.quantity}</td>
                              <td>
                                {disc.used_count > 0 ?
                                  <Link to={'/used-voucher/' + disc.id} className="flex items-center btn btn-sm m-1 btn-outline-info" title={("Used Quantity")}>
                                    {disc.used_count}
                                  </Link>
                                  : ""}
                              </td>
                              <td>
                                <Link className="items-center m-1 btn-outline-dark btn btn-sm" to={'/discount-voucher/edit/' + disc.id}>
                                  {<i className="far fa-edit"></i> /* {I18n.t('Edit')} */}
                                </Link>

                                <a className="flex items-center btn btn-sm  btn-outline-danger"
                                  onClick={e => {
                                    setTempVarId(disc.id)
                                    setIsDelete(true)
                                  }}
                                >
                                  <i className="far fa-trash-alt" /> </a>&nbsp;

                                {
                                  disc.status == 0 ?
                                    <Link className="flex btn btn-sm btn-outline-primary" onClick={e => { activeVoucher(disc.id, disc.status) }}>
                                      Activate
                                    </Link> :
                                    <Link className="flex btn btn-sm btn-outline-primary" onClick={e => { activeVoucher(disc.id, disc.status) }}>
                                      Deactivate
                                    </Link>
                                }




                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>


              <div>
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />
              </div>
            </div>
            {is_delete === true ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title='Are you sure?'
                onConfirm={(e) => {
                  deleteVoucher()
                  setIsDelete(false)
                  // window.location.reload(false)
                }}
                onCancel={(e) => {
                  setIsDelete(false)
                  setTempVarId('')
                }}
                focusCancelBtn
              >
                Do you want to delete this user?
              </SweetAlert>
            ) : ([]
            )}

          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DiscountVoucher);
