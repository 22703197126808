import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import axios from 'axios';
import { displayError, displaySuccess, getEditCarousel } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import config from '../config/config';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
const EditCarousel = (props) => {

  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [media, setMedia] = useState('');
  const [head_txt, setHeadTxt] = useState('');
  const [head_pt, setHeadPt] = useState('');
  const [desc_pt, setDescPt] = useState('');

  const updateCarousel = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    if (media === "") {
      path = path + '/admin/carousel/edit/' + props.match.params.id;
      axios.post(path, {
        description: description,
        head_text: head_txt,
        head_text_pt: head_pt,
        description_pt: desc_pt,
      }, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      })
        .then(function (response) {
          props.toggleLoader(false);
          displaySuccess('Carousel Updated Successfully');
          setTimeout(() => window.location.href = "/carousel-management", 3000);
        })
        .catch(function (error) {
          props.toggleLoader(false);
          displayError(error);
        })
    } else {
      const data = new FormData();
      data.append('head_text', head_txt);
      data.append('description', description);
      data.append('media', media);
      data.append('head_text_pt', head_pt);
      data.append('description_pt', desc_pt);
      axios.post(path + '/admin/carousel/edit/' + props.match.params.id, data, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }).then((success) => {
        props.toggleLoader(false);
        displaySuccess('Carousel Updated Successfully');
        setTimeout(() => window.location.href = "/carousel-management", 3000);
      }).catch((error) => {
        props.toggleLoader(false);
        displayError(error);
      })

    }
  }
  useEffect(async (e) => {
    var getEditUser_var = await getEditCarousel(props.match.params.id);
    setDescription(getEditUser_var.description);
    setHeadTxt(getEditUser_var.head_text);
    setImage(getEditUser_var.media.url);
    setDescPt(getEditUser_var.description_pt);
    setHeadPt(getEditUser_var.head_text_pt);
  }, [])
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/users"> Carousels </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page"> Edit Carousel</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Edit Carousel</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Head Text (english)</label>
                              <input type="text" className="form-control" placeholder='Head Text' onChange={e => setHeadTxt(e.target.value)} value={head_txt} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Description (english)</label>
                              <input type="text" className="form-control" placeholder='Description' onChange={e => setDescription(e.target.value)} value={description} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Head Text (portguese)</label>
                              <input type="text" className="form-control" placeholder='Head Text' onChange={e => setHeadPt(e.target.value)} value={head_pt} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Description (portguese)</label>
                              <input type="text" className="form-control" placeholder='Description' onChange={e => setDescPt(e.target.value)} value={desc_pt} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Image</label>
                              <input type="file" accept=".jpg,.jpeg,.png" className="form-control" placeholder="First Name" onChange={(e) => { setMedia(e.target.files[0]) }} />

                            </div>
                          </div>
                          <div className="col-md-6">

                            <img src={image} style={{ maxWidth: '300px' }} />
                          </div>
                        </div>
                        <button className="btn btn-primary" onClick={e => updateCarousel(e)}> <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCarousel);