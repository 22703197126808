import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import axios from 'axios';
import { displayError, displaySuccess, getOffers, getEditVoucher, getUserDisc } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import config from '../config/config';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { Link } from "react-router-dom";
import AsyncSelect from 'react-select/async';

const EditVoucher = (props) => {
  const [code, setCode] = useState('');
  const [discount_percentage, setDiscountPercentage] = useState('');
  const [discount_value, setDiscountValue] = useState('');
  const [quantity, setQuantity] = useState('');
  const [start_date, setStartDate] = useState(new Date());
  const [end_date, setEndDate] = useState(new Date());
  const [offer_id, setOfferId] = useState('');
  const [offers, setOffers] = useState([]);
  const [is_value, setIsValue] = useState(false);
  const [is_percentage, setIsPercentage] = useState(false);
  const [user_list, setUserList] = useState([]);
  const [user_id, setUserId] = useState('');
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const UpdateVoucher = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/admin/discount-voucher/update/' + props.match.params.id;
    axios.post(path, {
      code: code,
      discount_percentage: is_percentage === true ? "" : discount_percentage,
      discount_value: is_value === true ? "" : discount_value,
      quantity: quantity,
      start_date: start_date,
      end_date: end_date,
      offer_id: offer_id,
      user_id: user_id,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Voucher added successfully');
        setTimeout(() => window.location.href = "/discount-voucher", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }
  useEffect(async () => {
    var getEdit_var = await getEditVoucher(props.match.params.id);
    if (getEdit_var !== null) {
      setCode(getEdit_var.code);
      if (getEdit_var.discount_percentage) {
        setDiscountPercentage(getEdit_var.discount_percentage);
        setIsValue(true)
      }
      if (getEdit_var.discount_value !== null) {
        setDiscountValue(getEdit_var.discount_value);
        setIsPercentage(true)
      }
      setQuantity(getEdit_var.quantity);
      setStartDate(new Date(getEdit_var.start_date));
      setEndDate(new Date(getEdit_var.end_date));
      setOfferId(getEdit_var.offer_id)
      setUserId(getEdit_var.user_id);
      if(getEdit_var.user !== null){
        var temp_obj = setSelectedValue({ value: getEdit_var.user_id, label: getEdit_var.user.first_name + " "+getEdit_var.user.last_name+" (" +getEdit_var.user.email +")" })

      }
    }
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var get_offers = await getOffers();
    setOffers(get_offers);
    props.toggleLoader(false);
  }, [])
  const validPercent = (e) => {
    if (!/^[0-9]\d{0,1}(\.\d{1,2})$/.test(e.key)) {
      setIsValue(true);
    }
  }
  const validValue = (e) => {
    if (!/^[0-9]\d{0,1}(\.\d{1,2})$/.test(e.key)) {
      setIsPercentage(true);
    }
  }
  const handleInputChange = value => {
    setValue(value);
  };
  const handleChange = value => {
    setUserId(value.value);
    setSelectedValue(value);
    console.log(value);
  }
  const loadOptions = async (inputValue) => {
    var get_user = await getUserDisc(inputValue);
    var temp_arr = []
    get_user.map((list) => {
      var temp_obj = { value: list.id, label: list.full_name }
      temp_arr.push(temp_obj)
    })
    return (temp_arr)
  };
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/users">Users </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Voucher</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Edit Voucher</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Code</label>
                              <input type="text" className="form-control" placeholder="Code"
                                onChange={e => setCode(e.target.value)}
                                onKeyPress={(event) => {
                                  if (!/[A-Z0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  if (event.target.value.length > 10) {
                                    event.preventDefault();
                                  }
                                }}
                                value={code} disabled={true} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Discount Percentage</label>
                              <input type="text" className="form-control" placeholder="Discount Percentage"
                                onChange={e => setDiscountPercentage(e.target.value)}
                                disabled={is_percentage === true ? true : false}
                                onKeyPress={(e) => {
                                  validPercent(e.target.value)
                                }}
                                value={discount_percentage}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Discount Value</label>
                              <input type="text" className="form-control" placeholder="Discount Value"
                                onChange={e => setDiscountValue(e.target.value)}
                                disabled={is_value === true ? true : false}
                                onKeyPress={(e) => {
                                  validValue(e.target.value)
                                }}
                                value={discount_value}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" for="quantity">Quantity </label>
                              <input type="number" className="form-control" id="quantity" placeholder="Quantity" min={1} max={99} onChange={e => setQuantity(e.target.value)} value={quantity} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Start Date</label><br />
                              <DatePicker
                                selected={start_date}
                                dateFormat="yyyy-MM-dd"
                                onChange={(date) => setStartDate(date)}
                                customInput={
                                  <input
                                    style={{ width: '360px' }}
                                    type="text"
                                    className="form-control"
                                    placeholder={('Date')}
                                    value={start_date}
                                  />
                                }
                              // disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >End Date</label><br />
                              <DatePicker
                                selected={end_date}
                                dateFormat="yyyy-MM-dd"
                                onChange={(date) => setEndDate(date)}
                                minDate={
                                  new Date(start_date).setDate(
                                    new Date(start_date).getDate())
                                }
                                customInput={
                                  <input
                                    style={{ width: '360px' }}
                                    type="text"
                                    className="form-control"
                                    placeholder={('Date')}
                                  // value={start_date}
                                  />
                                }
                              // disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Offer</label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => {
                                  setOfferId(e.target.value)
                                }} >
                                <option value={""} >{('Select Offer')}</option>
                                {offers.map((list) =>
                                  <option value={list.id} selected={(list.id === offer_id) ? true : false}>{list.name}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >User</label>
                              {/* <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="color"
                                onChange={e => {
                                  setUserId(e.value)
                                }}
                                options={user_list}
                                // value={user_list.filter(obj => user_list.includes(obj.full_name))}
                              value={user_list.filter(({ value }) => value === user_id)}
                              /> */}
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={selectedValue}
                                getOptionLabel={e => e.label}
                                getOptionValue={e => e.value}
                                loadOptions={loadOptions}
                                onInputChange={handleInputChange}
                                onChange={handleChange}
                              // value={selectedValue.filter(({ value }) => value === user_id)}
                              />
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary" onClick={UpdateVoucher}> <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditVoucher);