import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { deactivateUser, getUsers } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import config from '../config/config';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { displayError, displaySuccess } from '../Utils/utils';
import ReactPaginate from 'react-paginate';

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [temp_var_id, setTempVarId] = useState('');
  const [current_page, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [is_delete, setIsDelete] = useState(false);

  useEffect(async () => {
    props.toggleLoader(true);
    var getUsers_var = await getUsers(current_page, search);
    setUsers(getUsers_var.data);
    setPageCount(getUsers_var.last_page);
    props.toggleLoader(false);
  }, [])

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    props.toggleLoader(true);
    var getUsers_var = await getUsers(selectedPage, search);
    setPageCount(getUsers_var.last_page)
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  };

  // const userStatus = async () => {
  //   var token = localStorage.getItem("token");
  //   props.toggleLoader(true);
  //   let path = config.siteUrl;
  //   var deactivateUser_var = await deactivateUser(temp_var_id, temp_var_status);
  //   window.location.reload()
  // }

  const searchUser = async (e) => {
    setSearch(e);
    var getusers_var = await getUsers(1, e);
    setPageCount(getusers_var.last_page)
    setUsers(getusers_var.data);
  }
  const deleteUser = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + "/user/delete/" + temp_var_id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess('Deleted Successfully');
        var getUsers_var = await getUsers(current_page, search);
        setUsers(getUsers_var.data);
        setPageCount(getUsers_var.last_page);
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      });
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar
              search_method={searchUser}
            />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Users</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Users Listing </h3>
                        </div>
                        <div class="col-6 text-right">
                          <Link to='/users/add'>
                            <a href="" class="btn btn-dark mr-2" data-toggle="tooltip" data-original-title="Edit product">
                              <span class="btn-inner--icon"><i class="fas fa-user-edit"></i></span>
                              <span class="btn-inner--text"> Add User</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th> Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>phone Number</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user) => (
                            <tr>
                              <td className="table-user">

                                <b>{user.first_name}  {user.last_name} </b>
                              </td>
                              <td className="table-user">
                                <b>{user.email} </b>
                              </td>

                                <td className="table-user">
                              {user.roles !== null ?
                                  <b>{user.roles.name} </b>
                                : ""}
                                </td>
                                <td>
                              {user.phone_number !== null ?
                                  <span className="text-muted">{user.phone_number} </span>
                                : ""}
                                </td>
                            
                                <td>
                                {user.description !== null ?
                                  <span className="text-muted">{user.description} </span>
                                : ""}
                                </td>
                              <td className="table-actions">
                                <Link to={'users/edit/' + user.id}>
                                  <a className="items-center m-1 btn-outline-dark btn btn-sm">
                                    {<i className="far fa-edit"></i> /* {I18n.t('Edit')} */}
                                  </a>
                                </Link>
                                <button className="flex items-center btn btn-sm  btn-outline-danger"
                                  onClick={e => {
                                    setTempVarId(user.id)
                                    setIsDelete(true)
                                  }}
                                >
                                  <i className="far fa-trash-alt" /> </button>
                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>


              <div>
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />
              </div>
            </div>
            {is_delete === true ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title='Are you sure?'
                onConfirm={(e) => {
                  deleteUser()
                  setIsDelete(false)
                  // window.location.reload(false)
                }}
                onCancel={(e) => {
                  setIsDelete(false)
                  setTempVarId('')
                }}
                focusCancelBtn
              >
                Do you want to delete this user?
              </SweetAlert>
            ) : ([]
            )}

          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Users);
