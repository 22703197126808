import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import axios from 'axios';
import { displayError, displaySuccess, getCountries, getStates, getCities } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import config from '../config/config';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
const AddCarousel = (props) => {

  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [media_id, setMediaId] = useState('');
  const [head_txt, setHeadTxt] = useState('');
  const [head_pt, setHeadPt] = useState('');
  const [desc_pt, setDescPt] = useState('');

  const addCarousel = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const data = new FormData();
    data.append('head_text', head_txt);
    data.append('description', description);
    data.append('media', image);
    data.append('head_text_pt',head_pt);
    data.append('description_pt',desc_pt);
    axios.post(path + '/admin/carousel/store', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      displaySuccess('Carousel Added Successfully');
      setTimeout(() => window.location.href = "/carousel-management", 3000);
    }).catch((error) => {
      props.toggleLoader(false);
      displayError(error);
    })
  }
  const upload = async (event) => {
    const token = localStorage.token;
    setImage(event.target.files[0]);
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/users"> Carousels </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page"> Add Carousel</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Add Carousel</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Head Text (english)</label>
                              <input type="text" className="form-control" placeholder="Head Text" onChange={e => setHeadTxt(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Description (english)</label>
                              <input type="text" className="form-control" placeholder="Description" onChange={e => setDescription(e.target.value)} />
                            </div>
                          </div>
                          
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Head Text (portguese)</label>
                              <input type="text" className="form-control" placeholder="Head Text" onChange={e => setHeadPt(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Description (portguese)</label>
                              <input type="text" className="form-control" placeholder="Description" onChange={e => setDescPt(e.target.value)} />
                            </div>
                          </div>

                        </div>
                        <div className='row'>
                        <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Image</label>
                              <input type="file" accept=".jpg,.jpeg,.png" className="form-control" placeholder="First Name" onChange={(e) => upload(e)} />

                            </div>
                          </div>
                          </div>
                        <button className="btn btn-primary" onClick={e => addCarousel(e)}> <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCarousel);