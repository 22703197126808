import React from 'react';
import { Link, NavLink } from 'react-router-dom';
const Leftbar = () => {
  return (
    <>
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs fixed-left">
        <div className="sidenav-header">
          <div className="sidenav-toggler">
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
          <a className="navbar-brand m-0" href="#">
            <img src="/img/logo-email.png" className="navbar-brand-img h-100 w-15" alt="..." />
            <span className="ms-1 font-weight-bold"> Dutra for Education </span>
          </a>
        </div>
        <div className="collapse navbar-collapse  w-auto">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/dashboard'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-th-large" />
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/users'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-users" />
                </div>
                <span className="nav-link-text ms-1">Users</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/discount-voucher'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i class="fab fa-cloudscale"></i>
                </div>
                <span className="nav-link-text ms-1">Discount Vouchers</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/carousel-management'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-users" />
                </div>
                <span className="nav-link-text ms-1">Carousel Management</span>
              </NavLink>
            </li>
            <li className="nav-item mt-3">
              <h6 className="pl-md-4 my-2 text-uppercase text-xs font-weight-bolder opacity-6">Account pages</h6>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/change-password'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-chalkboard-teacher" />
                </div>
                <span className="nav-link-text ms-1">Change Password</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}
export default Leftbar;