import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/mainstyle.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoginScreen from './LoginScreen/';
import Dashboard from './Dashboard/';
import Users from './Users/';
import AddUser from './AddUser/';
import EditUser from "./EditUser/";
import LoadingSpinner from "./components/LoadingSpinner";
import ChangePassword from "./ChangePassword";
import Carousel from "./Carousel";
import AddCarousel from "./AddCarousel";
import EditCarousel from "./EditCarousel";
import DiscountVoucher from './DiscountVoucher';
import AddVoucher from './AddVoucher';
import EditVoucher from './EditVoucher';
import usedVoucher from './usedVoucher';
function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/users" component={Users} />
          <ProtectedRoute exact path="/users/add" component={AddUser} />
          <ProtectedRoute exact path="/users/edit/:id" component={EditUser} />
          <ProtectedRoute exact path="/discount-voucher" component={DiscountVoucher} />
          <ProtectedRoute exact path="/discount-voucher/add" component={AddVoucher} />
          <ProtectedRoute exact path="/discount-voucher/edit/:id" component={EditVoucher} />
          <Route exact path="/change-password" component={ChangePassword} />
          <ProtectedRoute exact path="/carousel-management" component={Carousel} />
          <ProtectedRoute exact path="/carousel-management/add" component={AddCarousel} />
          <ProtectedRoute exact path="/carousel-management/edit/:id" component={EditCarousel} />
          <ProtectedRoute exact path="/used-voucher/:id" component={usedVoucher} />
        </Switch >
      </BrowserRouter >

      <LoadingSpinner />
    </React.Fragment >
  );
}

export default App;
