import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import apiUrl from "../config/apiPath";
import config from "../config/config";

export const displayErrorString = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })

  toast.error(error);
}

export const displayError = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  if (error.response.status === 422) {
    var row = [];
    Object.keys(error.response.data.errors).forEach((key, i) => {
      row.push(error.response.data.errors[key][0]);
    })
    const Msg = ({ closeToast, toastProps }) => (
      row.map((char, i) => {
        return (
          <li>
            {char}
          </li>
        );
      })
    )
    toast.error(Msg);
  } else if (error.response.status === 400 || error.response.status === 401) {
    toast.error(error.response.data.message);
  } else {
    toast.error(error.message);
  }

}

export const displaySuccess = (success) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })

  toast.success(success);
}

export const getUsers = async (page = '', search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/user?page=" + page + "&search=" + search, {
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getDiscountVoucher = async (page = '', search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/discount-voucher/?page=" + page + "&search=" + search, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return null
    })
  return response_final;
}
export const getEditUser = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getEditVoucher = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/discount-voucher/show/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}

// export const deactivateUser = async (id, is_active) => {
//     let path = config.siteUrl;
//     var token = localStorage.getItem('token');
//     var response_final = await axios.get(path + "/admin/user/status/" + id + "/" + !is_active, {
//         headers: { 'Content-Type': 'application/json' }
//     })
//         .then(function (response) {
//             return response.data;
//         })
//         .catch(function (error) {
//             return null;
//         })
//     return response_final;
// }
export const getCountries = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/country", {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return null
    });
  return response_final;
}

export const getStates = async (country_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/state/" + country_id, {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return null
    });
  return response_final;
}
export const getCities = async (state_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/city/" + state_id, {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return null
    });
  return response_final;
}
export const getCards = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/dashboard", {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return null
    });
  return response_final;
}
export const getPurchaseList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/recent/purchase", {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return []
    });
  return response_final;
}
export const getOffers = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/offer/", {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return null
    });
  return response_final;
}
export const getUsedQuantity = async (id, keyword = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/offer/applied-users/" + id + "?keyword=" + keyword, {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return []
    });
  return response_final;
}
export const voucherActive = async (id, status) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');

  var response_final = await axios.get(path + "/admin/discount-voucher/activate/" + id + "/status/" + status, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}
export const getUserDisc = async (keyword = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/offer/users" + "?keyword=" + keyword, {
    headers: { 'Content-Type': 'application/json', Authorization: ` Bearer${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return []
    });
  return response_final;
}

export const getCarousel = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/carousel/", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}


export const getEditCarousel = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/carousel/show/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return null;
    })
  return response_final;
}
